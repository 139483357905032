.fullScreen .ant-layout {
    margin-top: 0 !important;
    height: 100%;
}
.fullScreen .ant-layout-header {
    height: 50px;
}
.fullScreen .dpfSidebar {
    display: none;
}
.ant-layout-header > div{
    padding: 0px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
}
.fullScreen .ant-layout-header > div{
    line-height: 45px;
}
.fullScreen .ant-layout-header svg {
    display: none;
    width: 16px;
}
.fullScreen .ant-layout-header span {
    font-size: 12px;
}
.fullScreen .background {
    padding: 4px 0 0;
}
.fullScreen .custom-header {
    display: none;
}
.fullScreen .custom-header .redimind-logo {
    width: 150px;
}
.fullScreen .custom-header a > div {
    font-size: 20px;
}
.fullScreen .custom-header .username {
    font-size: 12px;
}
.fullScreen .custom-header .avatarContainer {
    height: 30px;
    width: 30px;
}
.fullScreen .custom-header .avatarContainer img {
    height: 30px;
}
.fullScreen .ant-layout-content {
    margin: 0 !important;
    padding: 0 !important;
}
.fullScreen .ant-layout-content > div {
    height: 100% !important;
}
.fullScreen .div-root-14, .fullScreen .dpfCanvas {
    height: 78% !important;
}
.fullScreen .drawContainer > div {
    height: 100%;
}
.fullScreen .drawContainer canvas {
    max-height: 95%;
}
