.Progress {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  padding: 0 15px;
}

.Progress__time {
  font-size: 0.7em;
}

.Progress__track {
  flex-grow: 1;
  margin: 5px;
}
