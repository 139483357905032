.Button {
  align-items: center;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: inherit;
  justify-content: center;
  margin: 0;
  opacity: 0.6;
  padding: 6px;
  transition: opacity 280ms ease-in-out;
}

.Button:hover,
.Button_active {
  opacity: 1;
}
