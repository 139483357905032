.Rac {
  align-items: center;
  color: white;
  display: inline-flex;
  font-family: 'Inter';
  font-size: 0;
  justify-content: center;
  position: relative;
}

.Rac__video {
  flex-grow: 1;
  pointer-events: none;
}

.Rac__overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.55));
}

.Rac__playback {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  padding: 0 10%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.Rac__controls {
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 0 10% 20px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.Rac__playback-button {
  font-size: 60px;
}

.Rac__progress-bar {
  flex-grow: 1;
  font-size: 1em;
}

.Rac__skip-time {
  font-size: 25px;
}
