.overlay {
    padding: 10px 10px 30px 30px;
    background-color: rgba(51, 51, 51, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25vw;
    margin-top: 20px;
}



.close-button {
    align-self: flex-end;
    cursor: pointer;
}

.menu-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
}

.menu-item-name{
    font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
color: #FFFFFF;
margin-left: 20px;
}

.dropdown-image{
    cursor: pointer;
}