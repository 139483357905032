.Volume {
  position: relative;
}

.Volume__slider {
  display: none;
  left: 50%;
  position: absolute;
  top: 0;
  transform: rotate(-90deg) translateY(-50%);
  transform-origin: 0 0;
  width: 60px;
}

.Volume__slider_active {
  display: block;
}
