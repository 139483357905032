.cards-layout {
    height: 100%;
    padding-top: 80px;
}

.nav-container {
    height: 40vh;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
}

.cards-wrapper {
    position: absolute;
    top: -10em;
    margin: 0 !important;
    padding: 0px 35px;
    width: 100%;
}


/* Card styles */

.card-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-layout:hover > .image{
    filter:invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg) brightness(94%) contrast(94%);
}

.card-layout:hover > .title{
    color: #81CDBA
}


.image{
text-align: center;
}


.title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-top: 40px;
    margin-bottom: 30px;
}

.sub-text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #333333;
    margin-top: 40px;
}

.divider {
    height: 2px;
    width: 33%;
    background: #81CDBA;
}