.Overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Overlay__container {
  display: none;
  height: 100%;
  width: 100%;
}

.Overlay__container_active {
  display: block;
}
