.main-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.big-text-white{
  margin-bottom: 0px !important;
font-style: normal;
font-weight: 900;
font-size: 64px;
line-height: 75px;
color: #FFFFFF;
}

.big-text-red{
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 75px;
  
  color: #EA203E;
}

.small-text-white{
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  
  color: #FFFFFF;
  
}


.promo-container{
  width: 28vw;
}