@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-upload-list{
  display: none;
}
.App {
    text-align: center;
}

.p {
    margin-bottom: 0em !important;
}

.background {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-image: url(/static/media/bg_img.3072d652.svg);
    padding: 27px 61px 0px 61px;
}

.custom-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.account-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}
#app-header {
    margin-top: 30px;
}
@media (max-width: 1200px) {
    .account-info-container .username {
        font-size: 12px;
    }
    .redimind-logo {
        width: 190px;
    }
    #app-header {
        margin-top: 10px;
    }
    .ant-table table { font-size: 10px; }
    .dpfToolbar > div svg {
        height: 30px;
    }
    .imgNavigation > div svg {
        width: 20px;
    }
    .nothingToLabel {
        font-size: 12px;
    }
    .imgNavigation {
        padding: 0 8px !important;
    }
    .dpfLabelcol .labelRow {
        height: 30%;
        overflow: hidden;
        overflow-y: scroll;
    }
    .dpfLabelcol .div-root-8 {
        width: 200px;
    }
    .promo-container {
        width: 50vh !important;
    }
    .main-container .main {margin-right: 0;}
    .cards-layout {
        padding-top: 0;
    }
    .cards-wrapper .wrapper {
        padding: 0;
        padding-top: 50px;
    }
    .cards-wrapper .wrapper img {
        width: 100px;
    }
    .cards-layout .nav-container {
        height: 28vh;
    }
    .cards-layout .promo-container {
        width: 100vw !important;
    }
    .promo-container .big-text-white { font-size: 40px; line-height: 35px;}
    .promo-container .big-text-red { font-size: 40px; line-height: 35px;}
    .cards-wrapper .wrapper .title {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
.avatarContainer {
    height: 60px;
    width: 60px;
    overflow: hidden;
    border: 2px solid #81CDBA;
    border-radius: 50%;
}
.avatar {
    height: 60px;
    box-sizing: border-box;
    object-fit: contain;
}

.username{
    font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
text-align: center;
color: #FFFFFF;
margin-left: 10px;
margin-right: 50px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Data Prep Factory CSS Annotator*/
.dpfSidebar{
    padding-right: 25px;
    width: 25%;
}

.dpfSidebar .listHeader {
    display: flex;
    align-items: center;
}

.dpfSidebar .listHeader p {
    margin-left:8px ;
    font-size: 14px;
    font-weight: bold;
}

.dpfSidelist{
    margin-bottom: 0;
    list-style: none;
    padding:0;
    margin-top: 20px;
}

.dpfSidelist a {
    display: flex;
    padding-left: 32px;
    position: relative;
}

.dpfSidelist a div{
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dpfSidelist .imageSelectItem span{
    color:#81CDBA;
}

.dpfSidelist .imageSelectItem ::before {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-left: 12px solid #81CDBA;
    border-bottom: 6px solid transparent;
    position: absolute;
    content: '';
    left: 15px;
    top: 10px;
}

.dpfLabelcol .labelRow h4{
    margin-top: 4px;
    color:#EA203E;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
}
    .labelRow{
    padding: 0 15px;
    padding-bottom: 30px;
}

.dpfLabeltext{
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

.visibleIocn, .INvisibleIocn {
    height: 17px!important;
}

.lockIcon, .UNlockIcon {
    height: 17px!important;
}

.deleteIocn{
    height: 17px!important;
}

.dpfToolbar{
    border-bottom:2px solid #C4C4C4;
    padding-top: 10px;
}

.dpfToolbar > div {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items:center;
}

.dpfToolbar > div svg {
    fill:#81CDBA;
    cursor: pointer;
}
.dpfToolbar > div svg:hover {
    fill:#EA203E;
}

.dpfToolbar > div .selected svg{
    fill:#3E5294;
}

.imgSwitcher{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
}

.imgSwitcher p {
    margin: 0;
}

.dpfBtn{
    border: 2px solid #81CDBA!important;
    color: #81CDBA!important;
    font-weight: 500 !important;
    font-size: 12px!important;
    min-width: 94px;
    height: 26px;
    padding: 0!important;
    background-color: #fff !important;
    box-shadow: none!important;
}


.dpfBtn:hover {
    border: 2px solid #EA203E!important;
    color: #EA203E!important;
}

.dpfSubmitbtn{
    width: 94px;
    height: 26px;
    background: #C4C4C4;
    border: 2px solid #81CDBA!important;
    color: #7F7F7F!important;
    font-weight: 500 !important;
    font-size: 12px!important;
    padding: 0!important;
    background-color: #C4C4C4 !important;
    box-shadow: none!important;
}

.dpfDrawlabel span{
    font-size: 12px;
    color: #333333;
}

.imgNavigation{
    padding: 0 15px;
}
.imgNavigation svg {
    fill:#81CDBA;
    cursor: pointer;
}
.imgNavigation:hover svg:hover{
    fill:#EA203E;
}

.dpfBtnDisabled {
    border: 2px solid gray !important;
    color: gray !important;
    font-weight: 500 !important;
    font-size: 12px!important;
    min-width: 94px;
    height: 26px;
    padding: 0!important;
    background-color: #fff !important;
    box-shadow: none!important;
    cursor: auto !important;
}
.dpfBtn:hover {
    border: 2px solid #EA203E!important;
    color: #EA203E!important;
    fill:#81CDBA;
    cursor: pointer;
}
.imgNavigation:hover  svg:hover .imgNavigation:hover svg:hover{
    fill:#EA203E;
}
.imgNavigation:hover .disabled svg:hover{
    fill:gray;
}
.imgNavigation .disabled svg{
    fill:gray;
}
.imgNavigation .disabled svg:hover{
    fill:gray;
    cursor: auto;
}
.ant-modal-confirm-btns{
    width: 100%;
    text-align: center;
}
.anticon.anticon-warning{
    text-align: center;
    width: 100%;
}
.anticon.anticon-warning svg{
    height: 100px;
    width: 100px;
}
.ant-modal-confirm-content {
    font-size: 18px !important;
    padding-top: 150px;
}
.ant-modal-confirm-btns .ant-btn {
    margin: 0px 70px;
    font-size: 20px;
    line-height: 20px;
}
.dpfSidelist .ant-list-pagination {
    text-align: center;
}
.dpf-loader{
    position: absolute !important;
    padding: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dpf-loader-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #ffffff5e;
}
.dpf-continue-editing{
    position: absolute;
    right: 10%;
    border: 2px solid #2292ff;
    padding: 7px;
    padding-right: 13px;
    color: #fff;
    background: #2292ff;
    border-radius: 4px;
}
.dpf-video-preview{
    position: relative;
    max-height: 150px;
    max-width: 250px;
    margin: 0 auto;
}
.dpf-video-preview-overlay{
    position: absolute;
    z-index: 9000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    opacity: 0.5;
}
.dpf-video-preivew-play{
    font-size: 42px;
    line-height: 140px !important;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9001;
}
.dpf-video-preview-modal-overlay{
    position: absolute;
    z-index: 9998;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.dpf-video-preview-modal{
    position: absolute;
    background: #b1b0b0;
    opacity: 0.8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.dpf-video-player{
    position: absolute;
    z-index: 9998;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dpf-video-player-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 600px;
    width: 900px;
}
.dpf-video-player-close{
    font-size: 32px;
    z-index: 9999;
    position: absolute;
    right: 0px;
    margin: 5%;
}

.black-logo-width {
    max-width: 200px;
}

.main {
  background: #FFFFFF;
  border: 2px solid #81CDBA;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 45px 27px 54px 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 460px;
  margin-right: 12em;
}

.ant-form-item {
  margin-bottom: 7px !important;
}

.signin-button {
  border: 0 !important ;
  background: #81CDBA !important;
  border-radius: 5px !important;
  padding: 10px 45px 10px 45px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #3E5294 !important;
  height: auto !important;
  
}

.signin-button:hover {
  background-color: #EA203E !important;
  color: #FFFFFF !important;

}


.cred-input {
  border: 1px solid #C4C4C4 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  width: 32vw !important;
  max-width: 400px !important;
}

.info {
  margin-top: 50px;
  margin-bottom: 16px;
}

.link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #C4C4C4;
}

.pass-icon {
  width: auto;
  height: auto;
}
.big-text-white {
    margin-bottom: 0px !important;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 75px;
    color: #FFFFFF;
}

.big-text-red {
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 75px;
    color: #EA203E;
}

.small-text-white {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
}

.promo-container {
    width: 28vw;
}
.main-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.big-text-white{
  margin-bottom: 0px !important;
font-style: normal;
font-weight: 900;
font-size: 64px;
line-height: 75px;
color: #FFFFFF;
}

.big-text-red{
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 75px;
  
  color: #EA203E;
}

.small-text-white{
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  
  color: #FFFFFF;
  
}


.promo-container{
  width: 28vw;
}
.wrapper {
    background: #FFFFFF;
    border: 2px solid #81CDBA;
    box-sizing: border-box;
    border-radius: 17px;
    padding: 45px 27px 54px 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.padding-50 {
    padding: 50px;
}
.cards-layout {
    height: 100%;
    padding-top: 80px;
}

.nav-container {
    height: 40vh;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
}

.cards-wrapper {
    position: absolute;
    top: -10em;
    margin: 0 !important;
    padding: 0px 35px;
    width: 100%;
}


/* Card styles */

.card-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-layout:hover > .image{
    -webkit-filter:invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg) brightness(94%) contrast(94%);
            filter:invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg) brightness(94%) contrast(94%);
}

.card-layout:hover > .title{
    color: #81CDBA
}


.image{
text-align: center;
}


.title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-top: 40px;
    margin-bottom: 30px;
}

.sub-text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #333333;
    margin-top: 40px;
}

.divider {
    height: 2px;
    width: 33%;
    background: #81CDBA;
}
.scrollable{
    overflow: hidden;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 20px;
    overflow-y: scroll;
    width: 100%;
    height: 40vh;

}
.scrollable .ant-card-cover {
    display: flex;

    justify-content: center;
    width: 100%;
    align-items: center;
}
.scrollable .ant-card-cover > * {
    display: block;
    width: 48%;
}
.Overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Overlay__container {
  display: none;
  height: 100%;
  width: 100%;
}

.Overlay__container_active {
  display: block;
}

.Button {
  align-items: center;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: inherit;
  justify-content: center;
  margin: 0;
  opacity: 0.6;
  padding: 6px;
  transition: opacity 280ms ease-in-out;
}

.Button:hover,
.Button_active {
  opacity: 1;
}

.Volume {
  position: relative;
}

.Volume__slider {
  display: none;
  left: 50%;
  position: absolute;
  top: 0;
  transform: rotate(-90deg) translateY(-50%);
  transform-origin: 0 0;
  width: 60px;
}

.Volume__slider_active {
  display: block;
}

.TimeLine__track {
  background: #ffffff55;
  border-radius: 10px;
  height: 5px;
  position: relative;
  width: 100%;
}

.TimeLine__scrubbed {
  background: #fff;
  border-radius: inherit;
  bottom: 0;
  left: 0;
  min-width: 4px;
  position: absolute;
  top: 0;
}

.TimeLine__hover-track {
  height: 100%;
  left: 0;
  padding: 18px 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 5;
}

.TimeLine__hover-info {
  display: none;
  position: absolute;
}

.TimeLine__hover-info_visible {
  display: block;
}

.TimeLine__hover-notch {
  background: #fff;
  height: 5px;
  width: 2px;
}

.TimeLine__hover-notch_inverted {
  background: #000;
  width: 3px;
}

.TimeLine__hover-time {
  background: #000a;
  border-radius: 4px;
  bottom: 0;
  font-size: 0.7em;
  padding: 5px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.Progress {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  padding: 0 15px;
}

.Progress__time {
  font-size: 0.7em;
}

.Progress__track {
  flex-grow: 1;
  margin: 5px;
}

.Rac {
  align-items: center;
  color: white;
  display: inline-flex;
  font-family: 'Inter';
  font-size: 0;
  justify-content: center;
  position: relative;
}

.Rac__video {
  flex-grow: 1;
  pointer-events: none;
}

.Rac__overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.55));
}

.Rac__playback {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  padding: 0 10%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.Rac__controls {
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 0 10% 20px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.Rac__playback-button {
  font-size: 60px;
}

.Rac__progress-bar {
  flex-grow: 1;
  font-size: 1em;
}

.Rac__skip-time {
  font-size: 25px;
}

.fullScreen .ant-layout {
    margin-top: 0 !important;
    height: 100%;
}
.fullScreen .ant-layout-header {
    height: 50px;
}
.fullScreen .dpfSidebar {
    display: none;
}
.ant-layout-header > div{
    padding: 0px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
}
.fullScreen .ant-layout-header > div{
    line-height: 45px;
}
.fullScreen .ant-layout-header svg {
    display: none;
    width: 16px;
}
.fullScreen .ant-layout-header span {
    font-size: 12px;
}
.fullScreen .background {
    padding: 4px 0 0;
}
.fullScreen .custom-header {
    display: none;
}
.fullScreen .custom-header .redimind-logo {
    width: 150px;
}
.fullScreen .custom-header a > div {
    font-size: 20px;
}
.fullScreen .custom-header .username {
    font-size: 12px;
}
.fullScreen .custom-header .avatarContainer {
    height: 30px;
    width: 30px;
}
.fullScreen .custom-header .avatarContainer img {
    height: 30px;
}
.fullScreen .ant-layout-content {
    margin: 0 !important;
    padding: 0 !important;
}
.fullScreen .ant-layout-content > div {
    height: 100% !important;
}
.fullScreen .div-root-14, .fullScreen .dpfCanvas {
    height: 78% !important;
}
.fullScreen .drawContainer > div {
    height: 100%;
}
.fullScreen .drawContainer canvas {
    max-height: 95%;
}

.overlay {
    padding: 10px 10px 30px 30px;
    background-color: rgba(51, 51, 51, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25vw;
    margin-top: 20px;
}



.close-button {
    align-self: flex-end;
    cursor: pointer;
}

.menu-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
}

.menu-item-name{
    font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
color: #FFFFFF;
margin-left: 20px;
}

.dropdown-image{
    cursor: pointer;
}
.profile-form1 {
    display: flex;
}
.profile-form .ant-input {
    width: 100% !important;
}

