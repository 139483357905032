.scrollable{
    overflow: hidden;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 20px;
    overflow-y: scroll;
    width: 100%;
    height: 40vh;

}
.scrollable .ant-card-cover {
    display: flex;

    justify-content: center;
    width: 100%;
    align-items: center;
}
.scrollable .ant-card-cover > * {
    display: block;
    width: 48%;
}