@import "~bootstrap/dist/css/bootstrap.css";
.App {
    text-align: center;
}

.p {
    margin-bottom: 0em !important;
}

.background {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-image: url('./Assets/Images/bg_img.svg');
    padding: 27px 61px 0px 61px;
}

.custom-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.account-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}
#app-header {
    margin-top: 30px;
}
@media (max-width: 1200px) {
    .account-info-container .username {
        font-size: 12px;
    }
    .redimind-logo {
        width: 190px;
    }
    #app-header {
        margin-top: 10px;
    }
    .ant-table table { font-size: 10px; }
    .dpfToolbar > div svg {
        height: 30px;
    }
    .imgNavigation > div svg {
        width: 20px;
    }
    .nothingToLabel {
        font-size: 12px;
    }
    .imgNavigation {
        padding: 0 8px !important;
    }
    .dpfLabelcol .labelRow {
        height: 30%;
        overflow: hidden;
        overflow-y: scroll;
    }
    .dpfLabelcol .div-root-8 {
        width: 200px;
    }
    .promo-container {
        width: 50vh !important;
    }
    .main-container .main {margin-right: 0;}
    .cards-layout {
        padding-top: 0;
    }
    .cards-wrapper .wrapper {
        padding: 0;
        padding-top: 50px;
    }
    .cards-wrapper .wrapper img {
        width: 100px;
    }
    .cards-layout .nav-container {
        height: 28vh;
    }
    .cards-layout .promo-container {
        width: 100vw !important;
    }
    .promo-container .big-text-white { font-size: 40px; line-height: 35px;}
    .promo-container .big-text-red { font-size: 40px; line-height: 35px;}
    .cards-wrapper .wrapper .title {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
.avatarContainer {
    height: 60px;
    width: 60px;
    overflow: hidden;
    border: 2px solid #81CDBA;
    border-radius: 50%;
}
.avatar {
    height: 60px;
    box-sizing: border-box;
    object-fit: contain;
}

.username{
    font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
text-align: center;
color: #FFFFFF;
margin-left: 10px;
margin-right: 50px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Data Prep Factory CSS Annotator*/
.dpfSidebar{
    padding-right: 25px;
    width: 25%;
}

.dpfSidebar .listHeader {
    display: flex;
    align-items: center;
}

.dpfSidebar .listHeader p {
    margin-left:8px ;
    font-size: 14px;
    font-weight: bold;
}

.dpfSidelist{
    margin-bottom: 0;
    list-style: none;
    padding:0;
    margin-top: 20px;
}

.dpfSidelist a {
    display: flex;
    padding-left: 32px;
    position: relative;
}

.dpfSidelist a div{
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dpfSidelist .imageSelectItem span{
    color:#81CDBA;
}

.dpfSidelist .imageSelectItem ::before {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-left: 12px solid #81CDBA;
    border-bottom: 6px solid transparent;
    position: absolute;
    content: '';
    left: 15px;
    top: 10px;
}

.dpfLabelcol .labelRow h4{
    margin-top: 4px;
    color:#EA203E;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 20px;
}
    .labelRow{
    padding: 0 15px;
    padding-bottom: 30px;
}

.dpfLabeltext{
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

.visibleIocn, .INvisibleIocn {
    height: 17px!important;
}

.lockIcon, .UNlockIcon {
    height: 17px!important;
}

.deleteIocn{
    height: 17px!important;
}

.dpfToolbar{
    border-bottom:2px solid #C4C4C4;
    padding-top: 10px;
}

.dpfToolbar > div {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items:center;
}

.dpfToolbar > div svg {
    fill:#81CDBA;
    cursor: pointer;
}
.dpfToolbar > div svg:hover {
    fill:#EA203E;
}

.dpfToolbar > div .selected svg{
    fill:#3E5294;
}

.imgSwitcher{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
}

.imgSwitcher p {
    margin: 0;
}

.dpfBtn{
    border: 2px solid #81CDBA!important;
    color: #81CDBA!important;
    font-weight: 500 !important;
    font-size: 12px!important;
    min-width: 94px;
    height: 26px;
    padding: 0!important;
    background-color: #fff !important;
    box-shadow: none!important;
}


.dpfBtn:hover {
    border: 2px solid #EA203E!important;
    color: #EA203E!important;
}

.dpfSubmitbtn{
    width: 94px;
    height: 26px;
    background: #C4C4C4;
    border: 2px solid #81CDBA!important;
    color: #7F7F7F!important;
    font-weight: 500 !important;
    font-size: 12px!important;
    padding: 0!important;
    background-color: #C4C4C4 !important;
    box-shadow: none!important;
}

.dpfDrawlabel span{
    font-size: 12px;
    color: #333333;
}

.imgNavigation{
    padding: 0 15px;
}
.imgNavigation svg {
    fill:#81CDBA;
    cursor: pointer;
}
.imgNavigation:hover svg:hover{
    fill:#EA203E;
}

.dpfBtnDisabled {
    border: 2px solid gray !important;
    color: gray !important;
    font-weight: 500 !important;
    font-size: 12px!important;
    min-width: 94px;
    height: 26px;
    padding: 0!important;
    background-color: #fff !important;
    box-shadow: none!important;
    cursor: auto !important;
}
.dpfBtn:hover {
    border: 2px solid #EA203E!important;
    color: #EA203E!important;
    fill:#81CDBA;
    cursor: pointer;
}
.imgNavigation:hover  svg:hover .imgNavigation:hover svg:hover{
    fill:#EA203E;
}
.imgNavigation:hover .disabled svg:hover{
    fill:gray;
}
.imgNavigation .disabled svg{
    fill:gray;
}
.imgNavigation .disabled svg:hover{
    fill:gray;
    cursor: auto;
}
.ant-modal-confirm-btns{
    width: 100%;
    text-align: center;
}
.anticon.anticon-warning{
    text-align: center;
    width: 100%;
}
.anticon.anticon-warning svg{
    height: 100px;
    width: 100px;
}
.ant-modal-confirm-content {
    font-size: 18px !important;
    padding-top: 150px;
}
.ant-modal-confirm-btns .ant-btn {
    margin: 0px 70px;
    font-size: 20px;
    line-height: 20px;
}
.dpfSidelist .ant-list-pagination {
    text-align: center;
}
.dpf-loader{
    position: absolute !important;
    padding: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dpf-loader-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #ffffff5e;
}
.dpf-continue-editing{
    position: absolute;
    right: 10%;
    border: 2px solid #2292ff;
    padding: 7px;
    padding-right: 13px;
    color: #fff;
    background: #2292ff;
    border-radius: 4px;
}
.dpf-video-preview{
    position: relative;
    max-height: 150px;
    max-width: 250px;
    margin: 0 auto;
}
.dpf-video-preview-overlay{
    position: absolute;
    z-index: 9000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    opacity: 0.5;
}
.dpf-video-preivew-play{
    font-size: 42px;
    line-height: 140px !important;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9001;
}
.dpf-video-preview-modal-overlay{
    position: absolute;
    z-index: 9998;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.dpf-video-preview-modal{
    position: absolute;
    background: #b1b0b0;
    opacity: 0.8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.dpf-video-player{
    position: absolute;
    z-index: 9998;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dpf-video-player-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 600px;
    width: 900px;
}
.dpf-video-player-close{
    font-size: 32px;
    z-index: 9999;
    position: absolute;
    right: 0px;
    margin: 5%;
}

.black-logo-width {
    max-width: 200px;
}
