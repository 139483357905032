.main {
  background: #FFFFFF;
  border: 2px solid #81CDBA;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 45px 27px 54px 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 460px;
  margin-right: 12em;
}

.ant-form-item {
  margin-bottom: 7px !important;
}

.signin-button {
  border: 0 !important ;
  background: #81CDBA !important;
  border-radius: 5px !important;
  padding: 10px 45px 10px 45px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #3E5294 !important;
  height: auto !important;
  
}

.signin-button:hover {
  background-color: #EA203E !important;
  color: #FFFFFF !important;

}


.cred-input {
  border: 1px solid #C4C4C4 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  width: 32vw !important;
  max-width: 400px !important;
}

.info {
  margin-top: 50px;
  margin-bottom: 16px;
}

.link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #C4C4C4;
}

.pass-icon {
  width: auto;
  height: auto;
}